<!--  -->
<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt />
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~"  v-model="valueList"  />
            <button @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt="" />
            <span>热门游戏</span>
          </div>
          <ul>
            <li :class="{ active: isShow == 0 }" @click="toClick(0)">首页</li>
            <li :class="{ active: isShow == 1 }" @click="toClick(1)">爱租号</li>
            <li :class="{ active: isShow == 2 }" @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="detail-contaner">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }"
            >爱租号首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>租号大厅</el-breadcrumb-item>
          <el-breadcrumb-item>{{ detail.big_game_name ||zhwList.game_name}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{
             detail.game_name||zhwList.game_server_name
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="!zhwList.pn">{{
            !detail.goods_title || detail.goods_title.substring(0, 41) 
          }}</el-breadcrumb-item>
           <el-breadcrumb-item v-else>{{
            zhwList.pn
          }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="box-card card">
          <div class="box-siwper">
            <swiper :options="swiperOption">
              <swiper-slide v-for="(item, index) in banners" :key="index">
                <div>                  
                  <img :src="item.location || item" alt preview/>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="box-detail">
            <p>
              {{
               detail.goods_title ||zhwList.pn
              }}
            </p>
            <div class="hot-div">
              <span class="box-span"
                >{{
                  detail.game_all_name ||`${zhwList.game_name}-${zhwList.game_server_name}-${zhwList.game_zone_name}`
                }}
                <i>{{detail.actity }}</i></span
              >
              <span class="zh-span" v-if="!zhwList.pn"> 账号热度：{{ detail.buy_count }}</span>
            </div>
            <div class="gifbag-div" v-if="false">
              <p>
                SVIP享租号 <span>9折</span>优惠，额外赠送
                <span>20.00</span>元现金红包
              </p>
              <i>立即开通></i>
            </div>
             <div class="date-div" v-if="!zhwList.pn">
              <ul>
                <li v-for="item in lease_type" :key="item.type">
                  <p v-if="item.isshow">¥{{item.price}}</p><p v-else>-</p>			  
                  <span>{{item.name}}</span>
                </li>
              </ul>
            </div>
            <div class="date-div" v-else>
              <ul>
                <li>
                  <p>¥{{zhwList.pmoney}}</p>			  
                  <span>时租</span>
                </li>
                 <li>
                  <p v-if="zhwList.p10">¥{{zhwList.p10}}</p><p v-else>-</p>			  
                  <span>日租</span>
                </li>
                 <li>
                  <p v-if="zhwList.p24">¥{{zhwList.p24}}</p><p v-else>-</p>			  
                  <span>十小时租</span>
                </li>
                 <li>
                  <p v-if="zhwList.p168">¥{{zhwList.p168}}</p><p v-else>-</p>			  
                  <span>周租</span>
                </li>
              </ul>
            </div>
         
            <div class="box-moneny">
              <div class="moneny-left">
                <i>套餐最低：</i>
                <span class="moneny-span">{{ detail.hour_lease ||zhwList.pmoney}}</span
                >/小时起
                <div class="moneny-div">押金：<span style="color:#FF3414;">{{ detail.foregift || zhwList.bzmoney}}</span>元</div>
              </div>
              <div class="moneny-right" @click="$router.push('/download')">
                <img src="../../assets/detail/shqsh1.png" alt="" />
                <span>{{detail.is_show_text}}</span>
              </div>
            </div>
            <el-button type="primary" round @click="toPath">立即租用</el-button>
            <div class="box-bottom">
              <div class="bottom-left">
                <img src="../../assets/detail/tx.png" alt="" />
                <span>温馨提醒：禁止使用外挂，否则将扣除全部押金和租金</span>
              </div>
              <div class="bottom-right">
                <div class="bootom-image" @click="topCollent">
                  <img
                    src="../../assets/detail/wsc.png"
                    alt=""
                    v-if="collection"
                  />
                  <img src="../../assets/detail/ysc.png" alt="" v-else />
                  <span>收藏</span>
                </div>
                <!-- <div class="bootom-image">
                  <img src="../../assets/detail/jb.png" alt="" />
                  <span>举报</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-card">
          <div class="tab-zh">
            <div
              class="tab-top"
              :class="{ tabInfo: current == 0 }"
              @click="isShowclick(0)"
              v-if="!zhwList.pn"
            >
              账号信息
            </div>
            <div
              class="tab-top"
              :class="{ tabInfo: current == 1 }"
              @click="isShowclick(1)"
            >
              账号帮助
            </div>
          </div>
          <div class="tab-contants" v-if="current == 1">
            <div class="txt">
              <div class="txt-p">1.我租号，租号时间怎么算？</div>
              <div class="txt-title">
                下单成功后即开始计时，每次租号额外赠送10分钟时长。
              </div>
            </div>
            <div class="txt">
              <div class="txt-p">2.账号时间到了后，会立即被踢下线吗？</div>
              <div class="txt-title">
                ①手游账号时间到后不会被踢下线，指导下一个租客租用该账号时才会强制下线；
              </div>
              <div class="txt-title">
                ②端游没有开启到时下线功能的账号，到时不会下线开启了的账号时间到了会自动强制下线。
              </div>
            </div>
            <div class="txt">
              <div class="txt-p">
                3.遇到游戏账号密码错误无法登录等问题怎么办？
              </div>
              <div class="txt-title">
                请在下单15分钟内在订单页发起”申请维权“，15分钟内维权不扣租金，15分钟后默认租客已核查无误，会根据具体问题扣除相应租金。
              </div>
            </div>
          </div>
          <div class="tab-contants" v-else>
            <div class="contants-top" v-if="prototypelist">
              <ul>
                <li v-for="item in prototypelist" :key="item.id">
                  {{ item.key_name || item.key_proto }}:
                  {{ item.value || item.val }}
                </li>
              </ul>
            </div>
            <div class="contants-bottom" v-if="detail.hero_info">
              <h1>英雄</h1>
              <p>
                {{detail.hero_info}}
              </p>
            </div>
            <div class="contants-bottom" v-if="detail.skin_info">
              <h1>皮肤</h1>
              <p>
               {{detail.skin_info}}
              </p>
            </div>
          </div>
        </div>
        <div class="card tab-zhanghao" v-if="current == 0">
          <div class="top">账号描述</div>
          <p class="card-p">
            {{detail.remark}}
          </p>
        </div>
      </div>
    </div>
     <div class="tips" v-if="toShow">
       <com-login v-if="isOk" @loginGo= loginGo  @LoginShow= LoginShow></com-login>
       <com-register v-else @toRegister = toRegister  @registerShow= registerShow></com-register>
    </div>
     <!-- 电梯导航 -->
    <side-Bar></side-Bar>
  </layout>
</template>
 
<script>
import sideBar from "../../components/common/sidebar";
import layout from "../../components/layout";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import comLogin from "../../components/common/comLogin"
import comRegister from "../../components/common/comRegister"

import qs from 'qs' 

export default {
  name: "detail",
  data() {
    return {
      zhwList: {}, //租号玩


      token: '',
      valueList:'',
      isOk: true,
      toShow: false,
      isShow: 1,
      current: 0,
      currentMeony:1,
      collection: true,
      account_id: 0,
      //轮播设置
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
          bulletActiveClass: "my-bullet-active",
        },
      },
      // 轮播图数据
      banners: [],
      detail: {},
      prototypelist: [],
	lease_type:[]
    };
  },
  components: {
    layout,
    swiper,
    swiperSlide,
    comLogin,
    comRegister,
    sideBar
  },
  created() {

    // 租号玩
    this.token = sessionStorage.getItem('token')
    if(this.$route.params.id.indexOf("&") == -1) {
      this.account_id = this.$route.params.id;
      this.getDetailList();
    }else{
      this.zhwList = qs.parse(this.$route.params.id)
      this.banners = this.zhwList.pic_list
      this.current = 1
    }
  },
  mounted() {},
  methods: {
    toClick(id) {
      if (id == 0) {
        this.$router.push({
          path: "/home",
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/download",
        });
      } else {
        this.$router.push({
          path: "/lobby",
        });
      }
      this.isShow = id;
    },
    //   切换
    isShowclick(id) {
      this.current = id;
    },
    // 获取数据
    getDetailList() {
      this.$get("api/account_detail", { account_id: this.account_id }).then(
        (res) => {
          if (res.code == 0) {
            this.banners = res.ret.picture;
            this.detail = res.ret.detail;
            this.prototypelist = res.ret.prototypelist;
            this.lease_type = res.ret.lease_type;
            if (res.ret.is_collect == 1) {
              this.collection = false;
            } else {
              this.collection = true;
            }
          }
        }
      );
    },
    topCollent() {
      var that = this;
      var url = "";
      if (that.collection) {
        url = "api/collect/save";
        var account_id = that.account_id;
        that.addCollenct(url, account_id);

      } else {
        url = "api/collect/del";
        var account_ids = that.account_id;
        that.addCollenct(url, account_ids);
      }
    },
    addCollenct(url, account_id, msg) {
      this.$post(url, {
        account_id: account_id,
      }).then((res) => {
		if(res.code==0){
      this.collection = !this.collection;
      if( this.collection){
        this.$message.error("取消收藏");   
      }else{
        this.$message.success("收藏成功");
      }
		}else{
      this.$message.error(res.message)
    }
      });
    },
    // 跳转路由
    toPath() {
     if(this.token){
       if(this.$route.params.id.indexOf("&") == -1) {
        this.$router.push({
         path: `/order/${this.account_id}`
        });
       }else{
         this.$router.push({
         path: `/order/${qs.stringify(this.zhwList)}`
        });
       }
      
     }else{
       this.toShow = true
     }
    },
   


      loginGo(payload) {
        this.isOk = payload
      },
      toRegister(payload) {
        this.isOk = payload
      },
      LoginShow(payload) {
       this.toShow = payload
      },
      registerShow() {
        this.toShow = false
        this.isOk = true
      },

        goSerach() {
        if(this.valueList){
            this.$router.push({
            path: '/lobby',
            query: {
            key_word: this.valueList
            }
          })
        }
      
    },
  },
};
</script>
 <style type="text/css">
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 6px;
  background: #ccc;
  opacity: 0.6;
}
.my-bullet-active {
  background: #fff;
  opacity: 1;
  width: 24px;
}
</style>
<style lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
        .active:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
//
.detail {
  width: 100%;
  padding-bottom: 66px;
  background-color: #f0f2f5;
  .detail-contaner {
    width: 1200px;
    margin: 0 auto;
    .el-breadcrumb {
      padding: 25px 0;
    }
    .box-card {
      display: flex;
      justify-content: space-between;
      padding: 30px;
      margin-bottom: 20px;
      .box-siwper {
        width: 400px;
        height: 450px;
        border-radius: 12px;
        .swiper-container {
          margin-left: auto;
          margin-right: auto;
          position: relative;
          overflow: hidden;
          list-style: none;
          padding: 0;
          z-index: 1;
          border-radius: 12px;
          height: 450px;
          img {
            width: 100%;
            height: 450px;
          }
        }
      }
      .box-detail {
        width: 681px;
        // height: 500px;
        p {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 30px;
        }

        .hot-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0;
          .box-span {
            display: inline-block;
            font-size: 13px;
            color: #000000;
            i {
              color: #ff3414;
              margin-left: 26px;
              display: inline-block;
              width: 160px;
            }
          }
          .zh-span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #848a9e;
          }
        }
        .gifbag-div {
          width: 100%;
          height: 32px;
          border-radius: 4px;
          background: url("../../assets/detail/svip1.png") no-repeat center;
          background-size: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding:0 20px 0 95px;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #343434;
            span {
              color: #fc3838;
            }
          }
          i {
            width: 68px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            cursor: pointer;
          }
        }
        .date-div {
          margin: 19px 0 0;
          ul {
            display: flex;
            align-items: center;
            li {
              width: 102px;
              height: 102px;
              border-radius: 8px;
              text-align: center;
              background:rgba($color: #60C8FD, $alpha: .15);  
              margin-right: 10px;   
              display: flex;
              flex-direction: column;
              justify-content: center; 
              cursor: pointer;
              p {
                font-size: 20px;
                color: #3c7efe;
              }
              span {
                margin-top: 5px;
                font-size: 15px;
                color: #343434;
              }
            }
            .dateActive {
              background: linear-gradient(135deg, #60c8fd 0%, #7ea2fb 100%);
              p{
                color: #fff;
              }
              span{
              color: #fff;
              }
            }
          }
        }
        .box-moneny {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .moneny-left {
              font-size: 14px;
            i {
              font-style: normal;
              color: #000;
            }
            .moneny-span {
              color: #fc3838;
            }
            .moneny-div {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000;
            }
          }
        }
        .moneny-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 5px;
          }
        }
        .box-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          .bottom-left {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a2a6b8;
            img {
              width: 14px;
              height: 14px;
              margin-right: 16px;
            }
          }
          .bottom-right {
            display: flex;
            align-items: center;
            .bootom-image {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: Microsoft YaHei;
              margin-right: 16px;
              font-weight: 400;
              color: #a2a6b8;
              cursor: pointer;
              img {
                width: 14px;
                height: 14px;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .tab-card {
      margin-bottom: 20px;
      .tab-zh {
        display: flex;
        align-items: center;
        font-size: 14px;
        .tab-top {
          width: 130px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          cursor: pointer;
        }
        .tabInfo {
          background: #3c7efe;
          border-radius: 12px 12px 0px 0px;
          color: #ffffff;
          font-size: 16px;
        }
      }
      .tab-contants {
        padding: 62px 30px 0;
        .txt {
          margin-bottom: 30px;
          .txt-p {
            font-size: 16px;
            color: #000000;
          }
          .txt-title {
            font-size: 14px;
            color: #848a9e;
            margin-top: 15px;
          }
        }
        //
        .contants-top {
          width: 100%;
          height: 168px;
          background-color: #f0f2f5;
          border-radius: 4px;
          margin-bottom: 66px;
          ul {
            display: flex;
            flex-wrap: wrap;
            padding: 30px 0 0 39px;
            li {
              width: 25%;
              height: 36px;
              font-size: 14px;
              padding-right: 72px;
              color: #848a9e;
              overflow: hidden; //隐藏文字
              text-overflow: ellipsis; //显示...
              white-space: nowrap; //不换行
            }
          }
        }
        .contants-bottom {
          margin-bottom: 80px;
          h1 {
            font-size: 16px;
            color: #000000;
          }
          p {
            text-align: justify;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #848a9e;
            line-height: 23px;
          }
        }
      }
    }
    .tab-zhanghao {
      padding: 30px 30px;
      .top {
        font-size: 16px;
        color: #000000;
        padding-left: 14px;
        position: relative;
        line-height: 19px;
        margin-bottom: 47px;
      }
      .top::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 17px;
        background: #3c7efe;
        border-radius: 2px;
      }
      p {
        width: 100%;
        min-height: 86px;
        text-align: justify;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #848a9e;
        line-height: 23px;
      }
    }
  }
}
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
}

.tips{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
</style>